import scrollToSmooth, { easeInOutCubic } from 'scrolltosmooth';

export default class ScrollTo {
    constructor(options = {}) {
        this.container = options.container || 'html';
        this.duration = options.duration || options.duration === 0 ? options.duration : 500;
        this.easing = options.easing || easeInOutCubic;
        this.durationRelative = options.durationRelative || false;

        this.smoothScroll = new scrollToSmooth(this.container, {
            duration: this.duration,
            durationRelative: this.durationRelative,
            easing: this.easing
        });
        
        this.smoothScroll.init();
    }

    selector(selector, offset = 0) {
        let element = document.querySelector(selector);
        return this.element(element, offset);
    }

    element(element, offset = 0) {
        if (element) {
            let scroll = this.container === 'html' ? window.scrollY : this.container.scrollTop;
            this.smoothScroll.scrollTo(element.getBoundingClientRect().top + scroll - offset);
        }
    }

    position(position) {
        this.smoothScroll.scrollTo(position);
    }
}