import axios from "axios"

export default function() {
    return { 
        insurance_object: '',
        veterinary_care_insurance: '',
        life_insurance: '',
        insurance_amount: null,
        veterinary_care: null,
        veterinary_care_deductible: null,
        insurance_start_date: '',
        veterinary_care_insurance_name: '',
        life_insurance_name: '',
        veterinary_care_insurance_documents: [],
        life_insurance_documents: [],
        life_insurance_active: true,
        veterinary_care_insurance_active: true,
        veterinary_care_insurance_toggable: false,
        life_insurance_toggable: false,
        breed: '',
        sif_registered: '',
        personal_trainer: '',
        accept_terms: false,

        // Hälsodeklation
        has_health_declaration: false,
        purchase_date: '',
        insured_today: '',
        received_veterinary_care: '',
        selected_disease_injuries: [],
        had_disease_injury_without_veterinary_care: '',
        additional_information: '',

        // Insurely
        current_insurance_amount: '',
        current_insurance_company: '',
        
        // Meta
        price: '...',
        loading: false,
        errors: {},

        visible_veterinary_care_insurance_choices: [],
        visible_life_insurance_choices: [],

        updatePriceTimout: null,

        init() {
            this.getData().then(() => {
                this.setupWatchers();
                this.setDefaults();
                this.updatePrice();
                this.getMeta();
            });
        },

        saveCustomization() {
            this.loading = true;

            axios.post('/step/hastforsakring-anpassa/save', {
                veterinary_care_insurance: this.veterinary_care_insurance,
                life_insurance: this.life_insurance,
                insurance_amount: this.insurance_amount,
                veterinary_care: this.veterinary_care,
                veterinary_care_deductible: this.veterinary_care_deductible,
                insurance_start_date: this.insurance_start_date,
                veterinary_care_insurance_active: this.veterinary_care_insurance_active,
                life_insurance_active: this.life_insurance_active,
                has_health_declaration: this.has_health_declaration,
            }).then(response => {
                this.loading = false;
                window.location.href = '/#'+ response.data.next_step;
            }).catch(error => {
                this.loading = false;
                this.errors = error.response.data.errors;

                if (Object.keys(this.errors).length > 0) {
                    window.scrollto.selector('[name='+ Object.keys(this.errors)[0] +']', 120);
                }
            });
        },

        getData() {
            return new Promise((resolve, reject) => {
                axios.post('/step/hastforsakring-anpassa/data').then(response => {
                    let data = response.data;

                    for (let key in data) {
                        if (key === 'insurance_amount' && data[key]) {
                            this[key] = currencyFormat(data[key]) +' kr';
                        } else {
                            this[key] = data[key];
                        }
                    }

                    resolve();
                }).catch(error => {
                    console.error(error);
                    reject();
                });
            });
        },

        handleInsuranceAmountKeyup() {
            delete this.errors.insurance_amount;
            
            if (this.insurance_amount) {
                this.insurance_amount = currencyFormat(this.insurance_amount);
            } else {
                this.insurance_amount = '';
            }
        },

        getMeta() {
            axios.post('/step/hastforsakring-anpassa/meta', {
                veterinary_care_insurance: this.veterinary_care_insurance || 'basic',
                life_insurance: this.life_insurance || 'basic',
            }).then(response => {
                this.veterinary_care_insurance_name = response.data.veterinary_care_insurance_name;
                this.life_insurance_name = response.data.life_insurance_name;
                this.veterinary_care_insurance_documents = response.data.veterinary_care_insurance_documents;
                this.life_insurance_documents = response.data.life_insurance_documents;
                this.veterinary_care_insurance_toggable = response.data.veterinary_care_insurance_toggable;
                this.life_insurance_toggable = response.data.life_insurance_toggable;
            }).catch(error => {
                console.error(error);
            });
        },

        updatePrice() {
            clearTimeout(this.updatePriceTimout);
            this.updatePriceTimout = setTimeout(() => {
                this.price = '...';

                axios.post('/step/hastforsakring-anpassa/price', {
                    veterinary_care_insurance: this.veterinary_care_insurance || 'basic',
                    life_insurance: this.life_insurance || 'basic',
                    insurance_amount: this.insurance_amount,
                    veterinary_care: this.veterinary_care,
                    veterinary_care_deductible: this.veterinary_care_deductible,
                    insurance_start_date: this.insurance_start_date,
                    veterinary_care_insurance_active: this.veterinary_care_insurance_active,
                    life_insurance_active: this.life_insurance_active,
                    sif_registered: this.sif_registered,
                    personal_trainer: this.personal_trainer,
                }).then((response) => {
                    this.price = response.data.price;
                }).catch((error) => {
                    this.price = 0;

                    for (let key of ['insurance_amount']) {
                        if (error.response.data.errors[key]) {
                            this.errors[key] = error.response.data.errors[key];
                        }
                    }
                            
                    if (error.response.status === 419) {
                        window.location.reload();
                    }
                });
            }, 250);
        },

        setupWatchers() {
            this.$watch('veterinary_care_insurance', (value) => {
                this.setDefaults();
            });

            this.$watch('veterinary_care_insurance, life_insurance, veterinary_care_insurance_active, life_insurance_active', () => {
                this.getMeta();
                this.updatePrice();
            });

            this.$watch('veterinary_care, veterinary_care_deductible, insurance_start_date, sif_registered, personal_trainer', () => {
                this.updatePrice();
            });

            this.$watch('visible_veterinary_care_insurance_choices', (choices) => {
                for (let section of Array.from(document.querySelectorAll('[data-veterinary-care-insurance]'))) {
                    if (choices.includes(section.dataset.veterinaryCareInsurance)) {
                        section.classList.remove('hidden');
                    } else {
                        section.classList.add('hidden');
                    }
                }
            });

            this.$watch('visible_life_insurance_choices', (choices) => {
                for (let section of Array.from(document.querySelectorAll('[data-life-insurance]'))) {
                    if (choices.includes(section.dataset.lifeInsurance)) {
                        section.classList.remove('hidden');
                    } else {
                        section.classList.add('hidden');
                    }
                }
            });

            let updatePriceTimout = null;
            this.$watch('insurance_amount', (value) => {                
                clearTimeout(updatePriceTimout);
                updatePriceTimout = setTimeout(() => {
                    this.updatePrice();
                }, 500);
            });
        },

        setDefaults() {
            this.visible_veterinary_care_insurance_choices = this.visibleVeterinaryCareInsuranceChoices();
            this.visible_life_insurance_choices = this.visibleLifeInsuranceChoices();

            if (!this.visibleVeterinaryCareInsuranceChoices().includes(this.veterinary_care_insurance)) {
                this.veterinary_care_insurance = this.defaultVeterinaryCareInsurance();
            }

            if (!this.visibleLifeInsuranceChoices().includes(this.life_insurance)) {
                this.life_insurance = this.defaultLifeInsurance();
            }

            if (this.veterinary_care_insurance === 'icelandic') {
                this.veterinary_care = 150000;
                this.veterinary_care_deductible = 25;
            } else if (this.veterinary_care_insurance === 'fetal') {
                this.veterinary_care = 100000;
                this.veterinary_care_deductible = 25;
            } 
            
            // else {
            //     this.veterinary_care = 50000;
            //     this.veterinary_care_deductible = 25;
            // }
        },

        showIslandicHorseOptions() {
            return this.breed === 'Islandshäst' && this.competes !== 'Ja' && this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';  
        },

        hasMultipleLifeinsuranceOptions() {
            return this.showElement('disaster_life_insurance') && (this.showElement('basic_life_insurance') || this.showElement('special_life_insurance') || this.showElement('premium_life_insurance'));
        },
        
        defaultVeterinaryCareInsurance() {
            if (this.showIslandicHorseOptions()) {
                return 'icelandic';
            } else if (this.showElement('premium_veterinary_care_insurance')) {
                return 'premium';
            } else if (this.insurance_object === 'horse_breeding') {
                return 'breeding';
            } else if (this.insurance_object === 'horse_fetal') {
                return 'fetal';
            } else {
                return 'special';
            }
        },
        
        defaultLifeInsurance() {
            if (this.competes !== 'Ja' && this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal') {
                return 'premium';
            } else if (this.insurance_object === 'horse_breeding') {
                return 'breeding';
            } else if (this.insurance_object === 'horse_fetal') {
                return 'fetal';
            } else if (this.more_than_15_years_old) {
                return 'disaster';
            } else {
                return 'special';
            }
        },
        
        showElement(element) {
            if (element === 'icelandic_veterinary_care_insurance') {
                return this.showIslandicHorseOptions();
            } else if (element === 'premium_veterinary_care_insurance') {
                return this.competes !== 'Ja' && this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'special_veterinary_care_insurance') {
                return this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'basic_veterinary_care_insurance') {
                return this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'breeding_veterinary_care_insurance') {
                return this.insurance_object === 'horse_breeding';
            } else if (element === 'fetal_veterinary_care_insurance') {
                return this.insurance_object === 'horse_fetal';
            } 
            
            if (element === 'basic_life_insurance') {
                return this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'special_life_insurance') {
                return this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'premium_life_insurance') {
                return this.competes !== 'Ja' && this.less_than_15_years_old && this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'disaster_life_insurance') {
                return this.insurance_object !== 'horse_breeding' && this.insurance_object !== 'horse_fetal';
            } else if (element === 'breeding_life_insurance') {
                return this.insurance_object === 'horse_breeding';
            } else if (element === 'fetal_life_insurance') {
                return this.insurance_object === 'horse_fetal';
            }
            
            if (element === 'veterinary_care_50000') {
                return this.veterinary_care_insurance !== 'icelandic' && this.veterinary_care_insurance !== 'fetal';
            }

            if (element === 'veterinary_care_100000') {
                return this.veterinary_care_insurance !== 'icelandic';
            }

            if (element === 'veterinary_care_150000') {
                return this.veterinary_care_insurance !== 'fetal';
            }
            
            if (element === 'veterinary_care_deductible_50') {
                return this.veterinary_care_insurance !== 'icelandic' && this.veterinary_care_insurance !== 'fetal';
            } 
        
            return true;
        },

        visibleVeterinaryCareInsuranceChoices() {
            return ['basic', 'special', 'premium', 'icelandic', 'breeding', 'fetal'].filter(choice => this.showElement(choice + '_veterinary_care_insurance'));
        },

        visibleLifeInsuranceChoices() {
            return ['basic', 'special', 'premium', 'disaster', 'breeding', 'fetal'].filter(choice => this.showElement(choice + '_life_insurance'));
        }
    }
}