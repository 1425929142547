import axios from "axios"

export default function() {
    return { 
        timeout: null,
        visible: false,
        qr: '',
        autostart: '',
        status_message: '',

        sign_url: '',
        cancel_url: '',
        status_url: '',

        complete: false,
        time: '',

        init() {
            const url = new URL(window.location.href);
            const params = url.searchParams;

            if (!params.has('t')) {
                params.set('t', Date.now().toString());
                window.history.replaceState(null, '', url);
            }

            this.time = params.get('t');

            if (sessionStorage.getItem('bankid_session_started_'+ this.time)) {
                try {
                    let session = JSON.parse(sessionStorage.getItem('bankid_session_started_'+ this.time));

                    if (session) {
                        this.cancel_url = session.cancel_url;
                        this.status_url = session.status_url;

                        this.visible = true;

                        setTimeout(() => {
                            if (['STARTED', 'USER_SIGN', 'OUTSTANDING_TRANSACTION', ''].includes(_bankid.status_message)) {
                                _bankid.status();
                            }
                        });
                        console.log('BankID status', _bankid.status_message);
                        
                    }
                } catch (e) {
                }
            }

            let _bankid = this;
            document.addEventListener("visibilitychange", function() {
                if (document.visibilityState === "visible") {
                    console.log('App active again. Checking bankid status.');
                    
                    if (['STARTED', 'USER_SIGN', 'OUTSTANDING_TRANSACTION'].includes(_bankid.status_message)) {
                        _bankid.status();
                        console.log('BankID status', _bankid.status_message);
                    }
                }
            });
        },

        sign(sign_url, cancel_url, status_url) {
            this.visible = true;
            this.sign_url = sign_url;
            this.cancel_url = cancel_url;
            this.status_url = status_url;

            sessionStorage.setItem('bankid_session_started_'+ this.time, JSON.stringify({
                started: true,
                cancel_url: cancel_url,
                status_url: status_url
            }));

            return axios.post(this.sign_url, { redirect: window.location.href }).then(response => {
                let data = response.data;
                
                this.qr = data.qr;
                this.autostart = data.autostart;
                this.status_message = data.status;

                // // Skip bankid:
                // if (data.status === 'COMPLETE' && data.redirect) {
                //     clearTimeout(this.timeout);
                //     window.location.href = data.redirect;
                // }

                this.status();
            });
        },

        cancel() {
            this.visible = false;
            clearTimeout(this.timeout);
            sessionStorage.removeItem('bankid_session_started_'+ this.time);
            
            return axios.post(this.cancel_url).then(response => {
                this.qr = '';
                this.status_message = '';
                this.autostart = '';
            });
        },

        status() {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                axios.post(this.status_url).then(response => {
                    let data = response.data;
                
                    if (!data.qr) {
                        clearTimeout(this.timeout);
                        this.qr = '';
                    } else {
                        this.qr = data.qr;
                    }

                    if (data.status === 'USER_SIGN') {
                        console.log('Waiting for user sign.');
                    }

                    console.log(data.status);

                    if (['USER_CANCEL', 'No such order'].includes(data.status)) {
                        this.cancel();
                        return;
                    }    

                    if (['ERROR'].includes(data.status)) {
                        setTimeout(() => {
                            if (this.status_message === 'ERROR') {
                                this.cancel();
                            }
                        }, 10000);

                        return;
                    }                    

                    if (!this.complete) {
                        this.status_message = data.status;

                        if (data.status === 'COMPLETE' && data.redirect) {
                            sessionStorage.removeItem('bankid_session_started_'+ this.time);
                            clearTimeout(this.timeout);
                            this.complete = true;
                            window.location.href = data.redirect;
                        }

                        this.status();
                    }
                }).catch(error => {
                    if (!this.complete) {
                        // clearTimeout(this.timeout);

                        setTimeout(() => {
                            this.status_message = 'ERROR';
                        }, 10000);
                    }

                    console.error(error);
                });
            }, 1000);
        }
    }
}