import './bootstrap';
import Alpine from 'alpinejs';
import * as Sentry from "@sentry/browser";
import HorseInsuranceData from './data/HorseInsuranceData';
import HorseInsurancePayment from './data/HorseInsurancePayment';
import BankIDData from './data/BankIDData';
import ScrollTo from './scrollto-helper';

// Sentry.init({
//   dsn: "https://910d9726b7fdfa92807b92370521b978@o4507940421959680.ingest.de.sentry.io/4507940508139600",
//   integrations: [],
// });

window.Alpine = Alpine;

window.scrollto = new ScrollTo({ duration: 750 });

document.addEventListener('alpine:init', () => {
  Alpine.data('HorseInsuranceData', HorseInsuranceData);

  Alpine.data('HorseInsurancePayment', HorseInsurancePayment);

  Alpine.data('BankIDData', BankIDData);
});

Alpine.start();

import.meta.glob([
    '../img/**',
    '../images/**',
    '../fonts/**',
]);

window.currencyFormat = function (number) {
  number = parseInt(String(number).replace(/[^0-9]+/g, ''));

  return new Intl.NumberFormat("sv-SE").format(number)
}