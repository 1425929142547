import axios from "axios"
import BankIDData from "./BankIDData";

export default function() {
    return { 
        horse_name: '',
        price: '',
        veterinary_care_insurance_name: '',
        life_insurance_name: '',
        veterinary_care_insurance_documents: [],
        life_insurance_documents: [],
        insurance_start_date: '',
        veterinary_care_deductible: '',
        insurance_amount: '',
        life_insurance: '',
        veterinary_care_insurance: '',
        veterinary_care: '',
        veterinary_care_insurance_active: '',
        life_insurance_active: '',
        chip: '',
        born: '',
        email: '',
        phone: '',
        payment_method: 'focus',
        payment_interval: '1',
        accept_terms: false,
        loading: false,

        insurance_accordion_open: false,
        horse_accordion_open: false,
        user_accordion_open: false,
        
        errors: {},
        submit_error: null,

        init() {
            this.bankid.init();

            this.getData().then(() => {
                this.getMeta();

                this.horse_accordion_open = !this.chip;
                this.user_accordion_open = !this.email || !this.phone;    
            });

            this.$watch('errors', errors => {
                if (errors['chip']) {
                    this.horse_accordion_open = true;
                }

                if (errors['email'] || errors['phone']) {
                    this.user_accordion_open = true;
                }
            });

            if (this.datalayer) {
                let datalayer_object = JSON.parse(JSON.stringify(this.datalayer));
                dataLayer.push(datalayer_object);
            }
        },

        save() {
            this.errors = {};

            axios.post('/step/hastforsakring-betalning/save', {
                accept_terms: this.accept_terms,
                payment_method: this.payment_method,
                payment_interval: this.payment_interval,
                email: this.email,
                phone: this.phone,
                chip: this.chip,
            }).then(response => {
                return this.bankid.sign(
                    '/step/hastforsakring-betalning/bankid_sign',
                    '/step/hastforsakring-betalning/bankid_cancel',
                    '/step/hastforsakring-betalning/bankid_status'
                ).catch(error => {
                    this.bankid.cancel();

                    if (error.response.status, error.response.data.errors['submit_error']) {
                        this.submit_error = error.response.data.errors['submit_error'];

                        this.bankid.cancel();
                    }
                });
            }).catch(error => {
                this.errors = error.response.data.errors;

                if (Object.keys(this.errors).length > 0) {
                    setTimeout(() => {
                        window.scrollto.selector('[name='+ Object.keys(this.errors)[0] +']', 120);
                    }, 250);
                }
            });
        },

        getData() {
            return new Promise((resolve, reject) => {
                axios.post('/step/hastforsakring-betalning/data', {

                }).then(response => {
                    let data = response.data;
                    
                    for (let key in data) {
                        this[key] = data[key];
                    }

                    resolve();
                }).catch(error => {
                    console.error(error);
                    reject();
                });
            });
        },

        getMeta() {
            axios.post('/step/hastforsakring-anpassa/meta', {
                veterinary_care_insurance: this.veterinary_care_insurance,
                life_insurance: this.life_insurance
            }).then(response => {
                let data = response.data;
                    
                for (let key in data) {
                    this[key] = data[key];
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }
}